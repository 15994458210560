<template>
    <PageHeader :title="title" />

    <tablecustom 
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="perms[371] == true || perms[2000] == true" 
        :tabs="othertabs"
        :addtabs="true"
        @addCategory="showFilterBox = true"
        @removeCategory="removeCategory"
        @create="showModalAddNew = true" 
        @open="open" 
        @remove="remove" 
        @changeFilter="changeFilter"  
        @getdata="getdata"
        @changelimit="changelimit"
        @changeTab="changeTab"
    />

    <viewbox 
        v-if="showModalview == true" 
        @close="closeModal" 
        :objCard="this.objCard" 
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
        :rows="rows" 
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen" 
        @getdata="this.getdata()"
    ></viewbox>

    <newbox 
        v-if="showModalAddNew == true" 
        @close="showModalAddNew = false"
    ></newbox>

    <!-- таби -->
    <filterTabs 
      v-if="showFilterBox" 
      @close="showFilterBox = false"
      @changeTabs="changeTabs"
      :othertabs="othertabs"
    >
    </filterTabs>

</template>

<script>
import PageHeader from "@/components/page-header";
import filterTabs from './filterTabs/index.vue'
import viewbox from './view/index.vue'
import newbox from './new.vue'
import tablecustom from '@/components/globaltable/index';
import { Updates } from '@/API.js';
import { storeS } from '@/store.js'
import {mutateStatusUpdates} from '../../usabilityScripts/globalMutate'

let apiServe = new Updates();

export default {
    data() {
        return {
            title: this.$t('Updates'),
            // data: {},
            rows: [],
            objCard: "",
            showModalview: false,
            showModalAddNew: false,
            ifNextopen: "",
            ifPrevopen: "",
            dataParams: {
                status: true,
                page: "changelog"
            },
            objPages: '',
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                createWorkerId: '',
                update: '',
                pr1: '',
                pr2: '',
                of: 'id', //deadline_date
                ot: 'desc' //asc
            },
            columns: [
                {
                    name: 'ID',
                    text: 'deadlineId',
                    align: 'text',
                    status: false
                },
                {
                    name: this.$t('project'),
                    text: "update",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("version"),
                    text: "version",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Deadline"),
                    text: "deadlineDate",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "left",
                    mutate: (item) => this.mutateStatus(item).name,
                    mutateClass: (item) => this.mutateStatus(item).color,
                    status: true,
                },
                {
                    name: this.$t("created"),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                }
            ],
            showFilterBox: false,
            othertabs: [],
        };
    },
    components: {
        PageHeader,
        newbox,
        tablecustom,
        viewbox,
        filterTabs
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(page){
            this.objParams.page = page != undefined ? page : '1';
            apiServe.getAllUpdates(this.objParams).then(result => {
                if(result.status === 'done') {
                    this.rows = result.data.items;
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error') + ` #370`);
                }
            })
        },
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getdata();
        },
        closeModal(){
            this.showModalview = false;
            this.getdata(this.objParams.page);
        },
        open(e){
            var id = e.deadlineId ? e.deadlineId : e;
            this.ifPrevopen = id == this.rows[0].deadlineId ? false : true;
            this.ifNextopen = id == this.rows[this.rows.length-1].deadlineId ? false : true;

            apiServe.getUpdate(id).then(result =>{
                if(result.status == 'done'){
                    this.objCard = result.data
                    this.showModalview = true
                } else {
                    this.$toast.error(this.$t("error"))
                }
            })
        },
        prevOpen(e){
            var current_id = e;
            var prev_id = ""; 

            for(var value in this.rows){
                if(this.rows[value].deadlineId == current_id){
                prev_id = this.rows[Number(value)-Number('1')].deadlineId;
                }
            }
            this.open(prev_id);
        },
        mutateStatus(item) {
            return mutateStatusUpdates(item)
        },
        nextOpen(e){
            var current_id = e;
            var next_id = "";

            for(var value in this.rows){
                if(this.rows[value].deadlineId == current_id){
                next_id = this.rows[Number(value)+Number('1')].deadlineId;
                }
            }
            this.open(next_id);
        },
        remove: function(e){
            apiServe.deleteUpdate(e.id).then(res => {
                if(res){
                    this.$toast.success(this.$t('Removed'));
                    this.getdata();
                }
            })
        },
        searchB(e){
            this.objParams.search = e
            this.getdata();
        },
        changeFilter(name, value){
            if(name == 'all'){
                this.objParams.search = '',
                this.objParams.update = '',
                this.objParams.createWorkerId = '',
                this.getdata();
            } else if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else if(name == 'workerId') {
                    this.objParams['createWorkerId'] = value;
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
        changeTab(name, value){
            var arrFilter = ['search','update', 'createWorkerId'];
            for(var item in arrFilter){
                if(arrFilter[item] == name){
                    this.objParams[name] = value
                } else {
                    this.objParams[arrFilter[item]] = '';
                }
            }
            this.getdata();
        },
        changeTabs(items){
            localStorage.setItem("Updatescheckbox", JSON.stringify(this.othertabs));
            localStorage.setItem("resultUpdates", this.othertabs.length)
        },
        removeCategory(e, i){
            this.othertabs.splice(i, 1)
            localStorage.setItem("Updatescheckbox", JSON.stringify(this.othertabs));
        },
    },
    computed: {
        perms() {
            return storeS.perms
        }
    },
    mounted() {

        this.othertabs = localStorage.getItem("Updatescheckbox") ? JSON.parse(localStorage.getItem("Updatescheckbox")) : [];

        this.eventBus.off('saveUpdate');
        this.eventBus.on('saveUpdate', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    },
};
</script>